<template>
  <div>页面走丢了哦</div>
</template>

<script type="text/ecmascript-6">
  export default {
    name: '404'
  }
</script>

<style lang="stylus" rel="stylesheet/stylus">

</style>
